body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.header-button {
  background: var(--orange-soda);
  color: var(--white);
  font-size: var(--fs-6);
  font-weight: var(--fw-700);
  padding: 8px 15px;
  background: #e31a23;
}

.header-button:is(:hover, :focus) { background: #650b0f; }

#navigation ul
{
    margin: 0;
    padding: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 14px;
}

#navigation ul li
{
    list-style-type: none;
    display: flex;
    justify-content: center;
    align-items: center;
    color: black;
    cursor: pointer;
}

#navigation-button {
  color: white;
  background-color: #E91C23;
  padding-left: 10px;
  padding-right: 10px;
  margin: 0px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 12px;
  font-weight: bold;
}

#navigation ul li div {
  color: white;
  padding-left: 10px;
  padding-right: 10px;
  margin: 0px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 13px;
}

#navigation ul li div:hover
{
    background-color: white;
    color: black;
    font-weight: bold;
    transition: background-color 300ms linear, color 300ms linear;;
    -webkit-transition: background-color 300ms linear, color 300ms linear;;
    -ms-transition: bbackground-color 300ms linear, color 300ms linear;;
    -o-transition: background-color 300ms linear, color 300ms linear;;
    -ms-transition: background-color 300ms linear, color 300ms linear;;
}

.center-crop {
  object-fit: cover; /* Cover the image */
 object-position: center;
}

/* #navigation li:not(:first-child):before {
    content: "|";
  
} */

.center {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.center-crop {
  width: 100px;
  height: 100px;
  background-position: center center;
  background-repeat: no-repeat;
}

.pointer {
  cursor: 'pointer';
}